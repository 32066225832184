import React from 'react';
import Layout from "../../components/Layout";
import {graphql, navigate} from "gatsby";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {CaseStudyTemplate} from "../../templates/what-we-have-done";
import {HTMLContent} from "../../components/Content";
import {Helmet} from "react-helmet";
import BreadCrumb from "../../components/BreadCrumb";

export default function ({location, data}) {
  const options = [...new Set(data.allMarkdownRemark.edges.map(edge => edge.node.frontmatter.category))];
  let content = (location.state && typeof location.state !== "undefined") ? data.allMarkdownRemark.edges.filter(edge => edge.node.id === location.state.id)[0] :
    data.allMarkdownRemark.edges[0];
  if(!content){
    content = data.allMarkdownRemark.edges[0];
  }
  const posts = data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.category === content.node.frontmatter.category);

  function onSelect(option) {
    const post = data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.category === option.value)[0];
    return navigate('/what-we-have-done', {state: {id: post.node.id}});
  }


  function isActive(title) {
    return content.node.frontmatter.title === title;
  }

  function isActiveCategory(category) {
    return category === content.node.frontmatter.category
  }

  const links = [
    {label: 'Home', value: '/'},
  ];

  return <Layout>

    <div className={'container case-study'}>
      <BreadCrumb currentPage={'What we have done'} links={links}/>
      <Dropdown options={options}
                onChange={onSelect}
                className={'categories'}
                controlClassName={'category-select'}
                arrowClassName='arrow'
                value={content.node.frontmatter.category} placeholder="Select an option"/>
      <div className={'outer'}>
        <aside>
          <h1> Case Studies</h1>
          <ul className={'desktop-categories'}>
            {options.map(o => <li key={o}>
              <button onClick={() => onSelect({value: o})}
                      className={isActiveCategory(o) ? 'active is-transparent' : 'is-transparent'}>
                {o}
              </button>
            </li>)}
          </ul>
        </aside>
        <div className={'main'}>
          <ul className={'posts'}>
            {posts.map(post => <li
              key={post.node.id}>
              <button className={isActive(post.node.frontmatter.title) ? 'active is-transparent' : 'is-transparent'}
                      onClick={() => navigate('/what-we-have-done', {state: {id: post.node.id}})}>
                {post.node.frontmatter.title}
              </button>
            </li>)}
          </ul>

          <CaseStudyTemplate
            content={content.node.html}
            contentComponent={HTMLContent}
            title={content.node.frontmatter.title}
            featuredimage={content.node.frontmatter.featuredimage}
            helmet={
              <Helmet titleTemplate="%s | Case Study">
                <title>{`${content.node.frontmatter.title}`}</title>
                <meta
                  name="description"
                  content={`${content.node.frontmatter.description}`}
                />
              </Helmet>
            }
          />
        </div>
      </div>
    </div>
  </Layout>
}

export const pageQuery = graphql`
 query CaseStudyPage {allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "what-we-have-done" } } }
        ) {
          edges {
            node {
              id
              html
              fields {
                slug
              }
              frontmatter {
                title
                category
                description
                icon {
                 publicURL
                }
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
        }
    }
    }
`;
